import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/common/Sidebar';
import HomePage from './pages/HomePage';
import Login from './auth/Login';
import Registration from './auth/Registration';
import Clienti from './pages/Clienti'; // Import new page components
import Contracte from './pages/Contracte';
import Facturare from './pages/Facturare';
import Activitati from './pages/Activitati';
import Utilizatori from './pages/Utilizatori';
import PrimeReact from 'primereact/api'; // Import PrimeReact
import { UserContext } from './auth/UserContext';
import { Navigate } from 'react-router-dom';

import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


import 'primeflex/primeflex.css';
import './index.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/themes/md-dark-indigo/theme.css'

const App = () => {
  const [theme, setTheme] = useState('saga-green');
  const [user, setUser] = useState(null);
 
  useEffect(() => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
  
    if (token && username) {
      setUser({ token, username });
    }
  }, []);
  

  const changeMyTheme = () => {
      PrimeReact.changeTheme(theme);
      setTheme(theme === 'saga-blue' ? 'saga-green' : 'saga-blue');
  };
  
  

  return (
    <UserContext.Provider value={{ user, setUser }}>
    <Router>
      <div id="layout-wrapper">
        <Sidebar />
        <div id="main-container" className="p-col">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/clienti" element={<Clienti />} />
            <Route path="/contracte" element={<Contracte />} />
            <Route path="/facturare" element={<Facturare />} />
            <Route path="/activitati" element={<Activitati />} />
            <Route path="/utilizatori" element={<Utilizatori />} />
            {/* Add routes for other pages */}
          </Routes>
        </div>
      </div>
    </Router>
    </UserContext.Provider>
  );
};

export default App;
