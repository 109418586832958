import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'http://95.111.242.140:5000',
    headers: {
        'Content-Type': 'application/json',
    },
});

export default {
    getClients() {
        return apiClient.get('/clients');
    },
    createClient(client) {
        return apiClient.post('/clients', client);
    },
    updateClient(id, client) {
        return apiClient.put(`/clients/${id}`, client);
    },
    deleteClient(id) {
        return apiClient.delete(`/clients/${id}`);
    },
};
