import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UtilizatorCard from '../Componente/UtilizatorCard';

const API_URL = 'http://localhost:5000';

const Utilizatori = () => {
    const [utilizatori, setUtilizatori] = useState([]);

    useEffect(() => {
        const fetchUtilizatori = async () => {
            try {
                const response = await axios.get(`${API_URL}/users`);
                setUtilizatori(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUtilizatori();
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '1rem', marginTop:"30px" }}>
            {utilizatori.map(utilizator => (
                <UtilizatorCard key={utilizator.id} utilizator={utilizator} style={{ width: '300px', height: '200px' }} />
            ))}
        </div>
    );
};

export default Utilizatori;
