import React from 'react';
import ClientTable from '../Componente/ClientTable.js'; // Make sure to import the ClientTable component

const Clienti = () => {
  return (
    <div>
      
      <ClientTable /> {/* Render the ClientTable component here */}
    </div>
  );
};

export default Clienti;
