import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Card } from 'primereact/card';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { updateUser, deleteUser } from '../api/userApi'; // Assuming this is the file where you have axios

// Keyframe animation for the background transition
const backgroundAnimation = keyframes`
    0% {
        height: 0%;
    }
    100% {
        height: 100%;
    }
`;

const StyledCard = styled(Card)`
    background-color: #f0f0f0; /* Light grey background */
    border-radius: 8px; /* Rounded borders */
    transition: transform 0.3s ease; /* Animation for zoom */
    display: flex;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
    border: none; /* Remove border */
    box-shadow: none; /* Remove shadow */
    position: relative;
    overflow: hidden; /* Ensure pseudo-element is contained within the card */

    &:hover {
        transform: scale(1.05); /* Zoom effect on hover */
    }

    &:hover::before {
        animation: ${backgroundAnimation} 0.3s forwards; /* Apply background animation */
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        background-color: #8688eb; /* Blue background color */
        z-index: 0;
        transition: height 0.2s ease; /* Smooth transition */
    }

    & > * {
        position: relative;
        z-index: 1; /* Ensure content is above the pseudo-element */
    }
`;

const AvatarContainer = styled.div`
    margin-right: 20px;
    display: flex;
    align-items: center;
`;

const StyledAvatar = styled(Avatar)`
    width: 80px;
    height: 80px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

const Actions = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 30px;
`;

const UtilizatorCard = ({ utilizator }) => {
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [editedUser, setEditedUser] = useState(utilizator);

    const handleEdit = async () => {
        try {
            await updateUser(utilizator.id, editedUser);
            setEditDialogVisible(false);
            alert('Utilizator actualizat cu succes');
        } catch (error) {
            alert('Eroare la actualizare');
        }
    };

    const handleDelete = async () => {
        try {
            await deleteUser(utilizator.id);
            alert('Utilizator șters cu succes');
        } catch (error) {
            alert('Eroare la ștergere');
        }
    };

    return (
        <StyledCard>
            <AvatarContainer>
                <StyledAvatar
                    className="user-avatar"
                    icon={!utilizator.avatar && 'pi pi-user'}
                    image={utilizator.avatar}
                    size="xlarge"
                    shape="circle"
                />
            </AvatarContainer>
            <Content>
                <div style={{ fontSize: '16px', marginBottom: '10px' }}>{utilizator.username}</div>
                <div style={{ fontSize: '14px', marginBottom: '5px' }}><strong>Hourly Rate:</strong> {utilizator.hourlyRate}</div>
                <div style={{ fontSize: '14px' }}><strong>Rol:</strong> {utilizator.role}</div>
            </Content>
            <Actions>
                <Button label="Editare" onClick={() => setEditDialogVisible(true)} />
                <Button label="Ștergere" onClick={handleDelete} />
            </Actions>
            <Dialog header="Editare utilizator" visible={editDialogVisible} onHide={() => setEditDialogVisible(false)}>
                <div className="p-field" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                    <label htmlFor="username">Nume utilizator</label>
                    <InputText id="username" value={editedUser.username} onChange={(e) => setEditedUser({ ...editedUser, username: e.target.value })} />
                </div>
                <div className="p-field" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                    <label htmlFor="hourlyRate">Tarif orar</label>
                    <InputText id="hourlyRate" value={editedUser.hourlyRate} onChange={(e) => setEditedUser({ ...editedUser, hourlyRate: e.target.value })} />
                </div>
                <div className="p-field" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                    <label htmlFor="role">Rol</label>
                    <InputText id="role" value={editedUser.role} onChange={(e) => setEditedUser({ ...editedUser, role: e.target.value })} />
                </div>
                <Button severity="success" label="Salvare" onClick={handleEdit} />
            </Dialog>
        </StyledCard>
    );
};

export default UtilizatorCard;
