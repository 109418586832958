import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import contractApi from '../api/contractApi'; // Import your contract API
import clientApi from '../api/clientApi'; // Import your client API

const ContractTable = () => {
  const toast = useRef(null);
  const [contracts, setContracts] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [contractForm, setContractForm] = useState({
    status: '',
    companie: '',
    clientId: null, // Use client ID instead of client object
  });
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const statusOptions = ['DRAFT', 'DERULARE', 'FINALIZAT', 'ANULAT'];

  useEffect(() => {
    const fetchContracts = async () => {
      const response = await contractApi.getContracts();
      setContracts(response.data);
    };

    const fetchClients = async () => {
      const response = await clientApi.getClients();
      setClients(response.data);
    };

    fetchContracts();
    fetchClients();
  }, []);

  const saveContract = async () => {
    const contractToSave = {
      ...contractForm,
      clientId: selectedClient ? selectedClient.id : null,
    };

    if (contractForm.id) {
      contractApi.updateContract(contractForm.id, contractToSave)
        .then((response) => {
          setContracts((prevContracts) =>
            prevContracts.map((contract) =>
              contract.id === contractForm.id ? response.data : contract
            )
          );
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Contract updated successfully!',
            life: 3000,
          });
        })
        .catch((error) => {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: `Error updating contract: ${error.message}`,
            life: 3000,
          });
        });
    } else {
      contractApi.createContract(contractToSave)
        .then((response) => {
          setContracts((prevContracts) => [...prevContracts, response.data]);
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Contract added successfully!',
            life: 3000,
          });
        })
        .catch((error) => {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: `Error creating contract: ${error.message}`,
            life: 3000,
          });
        });
    }

    setDialogVisible(false);
  };

  const deleteContract = async (contractToDelete) => {
    await contractApi.deleteContract(contractToDelete.id);
    setContracts(contracts.filter((contract) => contract.id !== contractToDelete.id));
  };

  const editContract = (contract) => {
    const client = clients.find(c => c.id === contract.clientId);
    setSelectedClient(client);
    setContractForm({ ...contract });
    setDialogVisible(true);
  };

  const addContract = () => {
    setSelectedClient(null);
    setContractForm({ status: '', companie: '', clientId: null });
    setDialogVisible(true);
  };

  const clientLookup = useMemo(() => {
    return clients.reduce((lookup, client) => {
      lookup[client.id] = client.client;
      return lookup;
    }, {});
  }, [clients]);

  const renderEditButton = (rowData) => (
    <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary" outlined onClick={() => editContract(rowData)} />
  );

  const renderDeleteButton = (rowData) => (
    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" outlined onClick={() => deleteContract(rowData)} />
  );

  const statusBodyTemplate = (rowData) => {
    let style = {};
    switch (rowData.status) {
      case 'DRAFT':
        style = {color: '#6366f1', border: '1px solid #6366f1', padding: '2px', borderRadius: '5px'};
        break;
      case 'DERULARE':
        style = {color: '#009364', border: '1px solid #009364', padding: '2px', borderRadius: '5px'};
        break;
      case 'FINALIZAT':
        style = {color: '#e6253a', border: '1px solid #e6253a', padding: '2px', borderRadius: '5px'};
        break;
      case 'ANULAT':
        style = {color: '#d44800', border: '1px solid #d44800', padding: '2px', borderRadius: '5px'};
        break;
      default:
        break;
    }
    return <span style={style}>{rowData.status}</span>;
  };

  return (
    <div>
      <Toast ref={toast} />
      <DataTable value={contracts} header={
        <div className="table-header">
          <h1>Manage Contracts</h1>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" placeholder="Global Search" />
          </span>
          <Button label="Add" onClick={addContract} style={{ marginLeft: '50px'}} rounded />
        </div>
      }>
        <Column field="status" header="Status" body={statusBodyTemplate} sortable />
        <Column field="companie" header="Company" sortable />
        <Column field="client" header="Client" body={(rowData) => clientLookup[rowData.clientId]} sortable />
        <Column body={renderEditButton} />
        <Column body={renderDeleteButton} />
      </DataTable>
      <Dialog header="Contract" visible={dialogVisible} onHide={() => setDialogVisible(false)}>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col">
            <label htmlFor="status">Status</label>
            <Dropdown id="status" value={contractForm.status} options={statusOptions} onChange={(e) => setContractForm({ ...contractForm, status: e.value })} placeholder="Select a Status" />
          </div>
          <div className="p-field p-col">
            <label htmlFor="company">Company</label>
            <InputText id="company" value={contractForm.companie} onChange={(e) => setContractForm({ ...contractForm, companie: e.target.value })} placeholder="Your Company Name" />
          </div>
          <div className="p-field p-col">
            <label htmlFor="client">Client</label>
            <Dropdown id="client" optionLabel="client" value={selectedClient} options={clients} onChange={(e) => setSelectedClient(e.value)} placeholder="Select a Client" />
          </div>
        </div>
        <Button label="Save" onClick={saveContract} severity="success" icon="pi pi-check" />
      </Dialog>
    </div>
  );
};

export default ContractTable;
