import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import clientApi from '../api/clientApi';

const HomePage = () => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const fetchClients = async () => {
      const response = await clientApi.getClients();
      const clients = response.data;

      const processClientsData = (clients) => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const clientsPerMonth = Array(12).fill(0); // Initialize an array with 12 zeros
    
        clients.forEach(client => {
            const date = new Date(client.createdAt);
            const month = date.getMonth(); // getMonth returns the month (0-11)
            clientsPerMonth[month]++;
        });
    
        return {
            labels: months,
            datasets: [
                {
                    label: 'Number of Clients',
                    data: clientsPerMonth,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderWidth: 1
                }
            ]
        };
    };
    
      // Here you need to process the clients data into a format suitable for the chart
      // This will depend on how your data is structured
      const data = processClientsData(clients);
      setChartData(data);
    };

    fetchClients();
  }, []);

  return (
    <div>
      <h1 className="p-m-0">Welcome to the Home Page!</h1>
      <p>This is a simple example of a homepage component.</p>
      <div style={{width: '50%', float: 'left'}}>
        <h2>New Clients Added</h2>
        <Chart type="line" data={chartData} />
      </div>
    </div>
  );
};

export default HomePage;
