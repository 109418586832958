// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-draft {
    color: #0000ff;
    border: 1px solid #0000ff;
    padding: 2px;
}

.status-derulare {
    color: #00ff00;
    border: 1px solid #00ff00;
    padding: 2px;
}

.status-finalizat {
    color: #ff0000;
    border: 1px solid #ff0000;
    padding: 2px;
}

.status-anulat {
    color: #ffff00;
    border: 1px solid #ffff00;
    padding: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".status-draft {\r\n    color: #0000ff;\r\n    border: 1px solid #0000ff;\r\n    padding: 2px;\r\n}\r\n\r\n.status-derulare {\r\n    color: #00ff00;\r\n    border: 1px solid #00ff00;\r\n    padding: 2px;\r\n}\r\n\r\n.status-finalizat {\r\n    color: #ff0000;\r\n    border: 1px solid #ff0000;\r\n    padding: 2px;\r\n}\r\n\r\n.status-anulat {\r\n    color: #ffff00;\r\n    border: 1px solid #ffff00;\r\n    padding: 2px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
