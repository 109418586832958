// Example Clienti.js
import React from 'react';
import ContractTable from '../Componente/ContractTable.js';

const Contracte = () => {
  return (
    <div>
     
      <ContractTable /> {/* Render the ClientTable component here */}
      {/* Your content for the Clienti page */}
    </div>
  );
};

export default Contracte;
