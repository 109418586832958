import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import clientApi from '../api/clientApi';
import { Toast } from 'primereact/toast';

import 'primeflex/primeflex.css';
import '../App.css';

const ClientTable = () => {
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [clients, setClients] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [clientForm, setClientForm] = useState({
        client: '',
        judet: '',
        localitate: '',
        email: '',
        telefon: '',
        domeniu: '',
        web: '',
        codFiscal: '',
        registrulComertului: '',
        tara: '',
        adresa: '',
        codIban: '',
        banca: ''
    });
    const [telefonValid, setTelefonValid] = useState(true);
    const [clientValid, setClientValid] = useState(true);
    const [judetValid, setJudetValid] = useState(true);
    const [localitateValid, setLocalitateValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [domeniuValid, setDomeniuValid] = useState(true);
    const [webValid, setWebValid] = useState(true);
    const [codFiscalValid, setCodFiscalValid] = useState(true);
    const [registrulComertuluiValid, setRegistrulComertuluiValid] = useState(true);
    const [taraValid, setTaraValid] = useState(true);
    const [adresaValid, setAdresaValid] = useState(true);
    const [codIbanValid, setCodIbanValid] = useState(true);
    const [bancaValid, setBancaValid] = useState(true);

    useEffect(() => {
        const fetchClients = async () => {
            const response = await clientApi.getClients();
            setClients(response.data);
        };

        fetchClients();
    }, []);

    const validateTelefon = (telefon) => {
        return telefon.length === 10;
    };

    const saveClient = async () => {
        if (!clientForm.client || !clientForm.judet || !clientForm.localitate || !clientForm.email || !clientForm.telefon || !clientForm.domeniu || !clientForm.web || !clientForm.codFiscal || !clientForm.registrulComertului || !clientForm.tara || !clientForm.adresa || !clientForm.codIban || !clientForm.banca) {
            // Dacă unul dintre câmpuri este gol, setăm starea de validare corespunzătoare pe false și nu salvăm datele
            setClientValid(!!clientForm.client);
            setJudetValid(!!clientForm.judet);
            setLocalitateValid(!!clientForm.localitate);
            setEmailValid(!!clientForm.email);
            setTelefonValid(!!clientForm.telefon);
            setDomeniuValid(!!clientForm.domeniu);
            setWebValid(!!clientForm.web);
            setCodFiscalValid(!!clientForm.codFiscal);
            setRegistrulComertuluiValid(!!clientForm.registrulComertului);
            setTaraValid(!!clientForm.tara);
            setAdresaValid(!!clientForm.adresa);
            setCodIbanValid(!!clientForm.codIban);
            setBancaValid(!!clientForm.banca);
            return;
        }

        if (!validateTelefon(clientForm.telefon)) {
            setTelefonValid(false);
            return;
        }

        if (clientForm.id) {
            clientApi.updateClient(clientForm.id, clientForm)
                .then(response => {
                    setClients(clients.map(client => client.id === clientForm.id ? response.data : client));
                    toast.current.show({severity:'success', summary: 'Success', detail:'Client updated successfully!', life: 3000});
                });
        } else {
            clientApi.createClient(clientForm)
                .then(response => {
                    setClients([...clients, response.data]);
                    toast.current.show({severity:'success', summary: 'Success', detail:'Client added successfully!', life: 3000});
                });
        }
        setDialogVisible(false);
    };

    const handleInputChange = (e, fieldName) => {
        const value = e.target.value;
        setClientForm({ ...clientForm, [fieldName]: value });

        // Resetăm starea de validare pentru fiecare câmp atunci când se completează date noi
        switch(fieldName) {
            case 'client':
                setClientValid(true);
                break;
            case 'judet':
                setJudetValid(true);
                break;
            case 'localitate':
                setLocalitateValid(true);
                break;
            case 'email':
                setEmailValid(true);
                break;
            case 'telefon':
                setTelefonValid(true);
                break;
            case 'domeniu':
                setDomeniuValid(true);
                break;
            case 'web':
                setWebValid(true);
                break;
            case 'codFiscal':
                setCodFiscalValid(true);
                break;
            case 'registrulComertului':
                setRegistrulComertuluiValid(true);
                break;
            case 'tara':
                setTaraValid(true);
                break;
            case 'adresa':
                setAdresaValid(true);
                break;
            case 'codIban':
                setCodIbanValid(true);
                break;
            case 'banca':
                setBancaValid(true);
                break;
            default:
                break;
        }
    };

    const deleteClient = async (clientToDelete) => {
        await clientApi.deleteClient(clientToDelete.id);
        setClients(clients.filter(client => client.id !== clientToDelete.id));
    };

    const editClient = (client) => {
        setClientForm(client);
        setDialogVisible(true);
    };

    const addClient = () => {
        setClientForm({ client: '', judet: '', localitate: '', email: '', telefon: '', domeniu: '', web: '', codFiscal: '', registrulComertului: '', tara: '', adresa: '', codIban: '', banca: '' });
        setDialogVisible(true);
    };

    const renderEditButton = (rowData) => {
        return <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" text raised onClick={() => editClient(rowData)} />;
    };
    
    const renderDeleteButton = (rowData) => {
        return <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" text raised onClick={() => deleteClient(rowData)} />;
    };

    return (
        <div>
            <Toast ref={toast} />
            <DataTable value={clients} globalFilter={globalFilter} header={
                <div className="table-header">
                    <h1 className="p-m-0">Manage Clients</h1>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                        {globalFilter && <i className="pi pi-times" style={{cursor: 'pointer', marginLeft: '10px'}} onClick={() => setGlobalFilter(null)} />}

                        <Button label="Add" onClick={addClient} style={{ marginLeft: '50px'}}  rounded />

                    </span>
                </div>
            }>
                <Column field="client" header="Client" sortable />
                <Column field="codFiscal" header="Cod fiscal" sortable />
               {/*<Column field="registrulComertului" header="Registrul Comertului" sortable /> */} 
               <Column field="tara" header="Tara" sortable />
               <Column field="judet" header="Judet" sortable />
                {/* <Column field="localitate" header="Localitate" sortable />*/} 
                {/*<Column field="adresa" header="Adresa" sortable />*/} 
                <Column field="email" header="Email" sortable />
                <Column field="telefon" header="Telefon" sortable />
                <Column field="web" header="Web" sortable />
                 {/* <Column field="codIban" header="Cod Iban" sortable />*/} 
                {/* <Column field="banca" header="Banca" sortable />*/} 
                <Column field="domeniu" header="Domeniu de activitate" sortable />
                
               
                <Column body={renderEditButton} />
                <Column body={renderDeleteButton} />
            
            </DataTable>
            <Dialog header="Client" visible={dialogVisible} onHide={() => setDialogVisible(false)}>
    <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col">
            <label htmlFor="client">Client {clientValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText style={{ marginBottom: '10px', borderColor: clientValid ? '' : 'red' }}  id="client" value={clientForm.client} onChange={(e) => handleInputChange(e, 'client')} />
        </div>
        <div className="p-field p-col">
            <label htmlFor="codFiscal">Cod fiscal {codFiscalValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText style={{ marginBottom: '10px', borderColor: codFiscalValid ? '' : 'red' }} id="codFiscal" value={clientForm.codFiscal} onChange={(e) => handleInputChange(e, 'codFiscal')} />
        </div>
        <div className="p-field p-col">
            <label htmlFor="registrulComertului">Registrul Comertului {registrulComertuluiValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText style={{ marginBottom: '10px', borderColor: registrulComertuluiValid ? '' : 'red' }} id="registrulComertului" value={clientForm.registrulComertului} onChange={(e) => handleInputChange(e, 'registrulComertului')} />
        </div>
        <div className="p-field p-col">
            <label htmlFor="tara">Tara {taraValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText style={{ marginBottom: '10px', borderColor: taraValid ? '' : 'red' }} id="tara" value={clientForm.tara} onChange={(e) => handleInputChange(e, 'tara')} />
        </div>
        <div className="p-field p-col">
            <label htmlFor="judet">Judet {judetValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText style={{ marginBottom: '10px', borderColor: judetValid ? '' : 'red' }} id="judet" value={clientForm.judet} onChange={(e) => handleInputChange(e, 'judet')} />
        </div>
        <div className="p-field p-col">
            <label htmlFor="localitate">Localitate {localitateValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText style={{ marginBottom: '10px', borderColor: localitateValid ? '' : 'red' }} id="localitate" value={clientForm.localitate} onChange={(e) => handleInputChange(e, 'localitate')} />
        </div>
        <div className="p-field p-col">
            <label htmlFor="adresa">Adresa {adresaValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText style={{ marginBottom: '10px', borderColor: adresaValid ? '' : 'red' }} id="adresa" value={clientForm.adresa} onChange={(e) => handleInputChange(e, 'adresa')} />
        </div>
        <div className="p-field p-col">
            <label htmlFor="email">Email {emailValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText style={{ marginBottom: '10px', borderColor: emailValid ? '' : 'red' }} id="email" value={clientForm.email} onChange={(e) => handleInputChange(e, 'email')} />
        </div>
        <div className="p-field p-col">
            <label htmlFor="telefon">Telefon {telefonValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText  keyfilter="int" style={{ marginBottom: '10px', borderColor: telefonValid ? '' : 'red' }} id="telefon" value={clientForm.telefon} onChange={(e) => handleInputChange(e, 'telefon')} />
        </div>
        <div className="p-field p-col">
            <label htmlFor="domeniu">Domeniu de activitate {domeniuValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText style={{ marginBottom: '10px', borderColor: domeniuValid ? '' : 'red' }} id="domeniu" value={clientForm.domeniu} onChange={(e) => handleInputChange(e, 'domeniu')} />
        </div>
        <div className="p-field p-col">
            <label htmlFor="web">Web {webValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText style={{ marginBottom: '10px', borderColor: webValid ? '' : 'red' }} id="web" value={clientForm.web} onChange={(e) => handleInputChange(e, 'web')} />
        </div>
        <div className="p-field p-col">
            <label htmlFor="codIban">Cod Iban {codIbanValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText style={{ marginBottom: '10px', borderColor: codIbanValid ? '' : 'red' }} id="codIban" value={clientForm.codIban} onChange={(e) => handleInputChange(e, 'codIban')} />
        </div>
        <div className="p-field p-col">
            <label htmlFor="banca">Banca {bancaValid ? null : <span style={{ color: 'red' }}>*</span>}</label>
            <InputText style={{ marginBottom: '10px', borderColor: bancaValid ? '' : 'red' }} id="banca" value={clientForm.banca} onChange={(e) => handleInputChange(e, 'banca')} />
        </div>
    </div>
    <Button label="Save" onClick={saveClient} severity="success" icon="pi pi-check" />
</Dialog>

           
        </div>
    );
};

export default ClientTable;
