// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* index.css */

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

#layout-wrapper {
  display: flex;
  flex-direction: column; /* Updated to column layout */
}

.layout-sidebar {
  width: 100%;
}

.custom-tab-menu .p-tabmenu-icon {
  font-size: 1.5em; /* Adjust the font-size as needed */
  border-radius: 0 !important;
}



body .p-tabmenu.p-component .p-tabmenu-nav li.p-tabmenuitem a {
  border-radius: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;EACE,SAAS;EACT,UAAU;EACV,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,sBAAsB,EAAE,6BAA6B;AACvD;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB,EAAE,mCAAmC;EACrD,2BAA2B;AAC7B;;;;AAIA;EACE,2BAA2B;AAC7B","sourcesContent":["/* index.css */\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: 'Arial', sans-serif;\n}\n\n#layout-wrapper {\n  display: flex;\n  flex-direction: column; /* Updated to column layout */\n}\n\n.layout-sidebar {\n  width: 100%;\n}\n\n.custom-tab-menu .p-tabmenu-icon {\n  font-size: 1.5em; /* Adjust the font-size as needed */\n  border-radius: 0 !important;\n}\n\n\n\nbody .p-tabmenu.p-component .p-tabmenu-nav li.p-tabmenuitem a {\n  border-radius: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
