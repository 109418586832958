import React, { useContext, useState } from 'react'; // Added useContext
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { UserContext } from '../../auth/UserContext'; // Make sure this path is correct
import { useNavigate } from 'react-router-dom';
import '../../index.css';


const Sidebar = () => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  

  const menuItems = [
    { label: 'Login', icon: 'pi pi-sign-in', to: '/login' },
    { label: 'Registration', icon: 'pi pi-user-plus', to: '/registration' },
    ...(user ? [
      { label: 'Home', icon: 'pi pi-home', to: '/' },
      { label: 'Clienti', icon: 'pi pi-users', to: '/clienti' },
      { label: 'Contracte', icon: 'pi pi-file', to: '/contracte' },
      { label: 'Facturare', icon: 'pi pi-money-bill', to: '/facturare' },
      { label: 'Activitati', icon: 'pi pi-calendar', to: '/activitati' },
      { label: 'Setari', icon: 'pi pi-cog', to: '/setari' },
      { label: user.username, icon: 'pi pi-user', to: '/profile' },
      { label: 'Utilizatori', icon: 'pi pi-user-edit', to: '/utilizatori' },
    ] : []),
  ];

  const handleTabChange = (e) => {
    if (e.value && e.value.to) {
      setActiveIndex(e.index);
      navigate(e.value.to);
    }
  };

  return (
    <div className="layout-sidebar">
      <TabMenu
        model={menuItems}
        activeIndex={activeIndex}
        onTabChange={handleTabChange}
        className="custom-tab-menu"
      />
      
    </div>
  );
};

export default Sidebar;