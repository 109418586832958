// Example Clienti.js
import React from 'react';

const Facturare = () => {
  return (
    <div>
      <h2>Facturare</h2>
      {/* Your content for the Clienti page */}
    </div>
  );
};

export default Facturare;
