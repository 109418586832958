// Activitati.js
import React from 'react';
import ActivitatiKanban from '../components/kanban/ActivitatiKanban';

const Activitati = () => {
  return (
    <div>
      <h1>Activitati</h1>
      <ActivitatiKanban />
    </div>
  );
};

export default Activitati;
