import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const ActivitatiKanban = () => {
  const [columns, setColumns] = useState({
    todo: {
      id: 'todo',
      title: 'To Do',
      tasks: [
        { id: 'task1', content: 'Task 1' },
        // Add other tasks for 'To Do'
      ],
    },
    inProgress: {
      id: 'inProgress',
      title: 'In Progress',
      tasks: [
        { id: 'task2', content: 'Task 2' },
        // Add other tasks for 'In Progress'
      ],
    },
    inReview: {
      id: 'inReview',
      title: 'In Review',
      tasks: [
        { id: 'task3', content: 'Task 3' },
        // Add other tasks for 'In Review'
      ],
    },
    completed: {
      id: 'completed',
      title: 'Completed',
      tasks: [
        { id: 'task4', content: 'Task 4' },
        // Add other tasks for 'Completed'
      ],
    },
  });

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return; // Dropped outside the list

    const startColumn = columns[source.droppableId];
    const endColumn = columns[destination.droppableId];

    if (startColumn === endColumn) {
      // Reorder within the same column
      const newTasks = [...startColumn.tasks];
      const [removed] = newTasks.splice(source.index, 1);
      newTasks.splice(destination.index, 0, removed);

      setColumns((prevColumns) => ({
        ...prevColumns,
        [startColumn.id]: {
          ...startColumn,
          tasks: newTasks,
        },
      }));
    } else {
      // Move between columns
      const startTasks = [...startColumn.tasks];
      const endTasks = [...endColumn.tasks];
      const [removed] = startTasks.splice(source.index, 1);
      endTasks.splice(destination.index, 0, removed);

      setColumns((prevColumns) => ({
        ...prevColumns,
        [startColumn.id]: {
          ...startColumn,
          tasks: startTasks,
        },
        [endColumn.id]: {
          ...endColumn,
          tasks: endTasks,
        },
      }));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="board" direction="horizontal" type="COLUMN">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} style={{ display: 'flex' }}>
            {Object.values(columns).map((column, columnIndex) => (
              <Draggable key={column.id} draggableId={column.id} index={columnIndex}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      margin: '8px',
                      width: '200px',
                      backgroundColor: snapshot.isDragging ? '#c1c1c1' : '#fff',
                      borderRadius: '4px',
                      boxShadow: snapshot.isDragging ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
                      ...provided.draggableProps.style,
                    }}
                  >
                    <div style={{ borderBottom: '1px solid #ddd', paddingBottom: '8px' }}>
                      <h3 {...provided.dragHandleProps}>{column.title}</h3>
                    </div>
                    <Droppable droppableId={column.id} type="TASK">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {column.tasks.map((task, taskIndex) => (
                            <Draggable key={task.id} draggableId={task.id} index={taskIndex}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    padding: '8px',
                                    borderBottom: '1px solid #ddd',
                                    backgroundColor: snapshot.isDragging ? '#f2f2f2' : '#fff',
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  {task.content}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ActivitatiKanban;
