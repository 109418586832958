import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { register } from '../api/userApi';

const Registration = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = async () => {
    try {
      await register(username, password);
      // Registration was successful
      // Redirect the user to the login page or somewhere else
    } catch (error) {
      // Handle registration errors
    }
  };

  return (
    <div>
      <h2>Register</h2>
      <div>
        <label htmlFor="username">Username</label>
        <InputText id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
      </div>
      <div>
        <label htmlFor="password">Password</label>
        <InputText id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <Button label="Register" onClick={handleRegister} />
    </div>
  );
};

export default Registration;
