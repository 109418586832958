import axios from 'axios';

const apiContracts = axios.create({
    baseURL: 'http://95.111.242.140:5000',
    headers: {
        'Content-Type': 'application/json',
    },
});

export default {
    getContracts() {
        return apiContracts.get('/contracts');
    },
    createContract(contract) {
        return apiContracts.post('/contracts', contract);
    },
    updateContract(id, contract) {
        return apiContracts.put(`/contracts/${id}`, contract);
    },
    deleteContract(id) {
        return apiContracts.delete(`/contracts/${id}`);
    },
};
