import React, { useState, useContext, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { login } from '../api/userApi';
import { UserContext } from './UserContext';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { Card } from 'primereact/card';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);
  const { setUser } = useContext(UserContext);
  const toast = useRef(null);

  const handleLogin = async () => {
    try {
      const token = await login(username, password);
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
      setUser({ token, username });
    } catch (error) { 
      toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Username or password is incorrect' });
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setUser(null);
  };

  return (
    <div className="p-d-flex p-jc-center p-ai-center p-mt-6 p-p-3" style={{ height: '100vh', alignItems: 'center' }}>
      <Toast ref={toast}></Toast>
      <Card className="mt-8 p-p-4 p-shadow-8 p-border-rounded p-w-full p-w-8-lg" style={{ maxWidth: '700px', margin: 'auto' }}>
        <h1 className="p-text-center p-mb-5" style={{ textAlign: 'center' }}>Welcome back</h1>
        <div className="p-mb-3" style={{ marginBottom: '20px' }}>
          <label htmlFor="username" className="p-d-block p-font-weight-bold p-mb-2">Username</label>
          <InputText id="username" value={username} onChange={(e) => setUsername(e.target.value)}  style={{ width: '100%' }} />
        </div>
        <div className="p-mb-3" style={{ marginBottom: '20px' }}>
          <label htmlFor="password" className="p-d-block p-font-weight-bold p-mb-2">Password</label>
          <Password id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="p-w-full" feedback={false} style={{ width: '100%' }} />
        </div>
        <div className="p-d-flex p-jc-between p-mb-6" style={{ marginBottom: '30px' }}>
          <div className="p-d-flex p-ai-center mb-4">
            <Checkbox inputId="rememberme" onChange={e => setChecked(e.checked)} checked={checked} className="p-mr-2 mb-1 mr-2" />
            <label htmlFor="rememberme">Remember me</label>
          </div>
          <Button label="Logout" className="p-ml-2" onClick={logout} />
        </div>
        <Button label="Sign In" icon="pi pi-user" className="p-w-full" onClick={handleLogin} style={{ width: '100%' }} />
      </Card>
    </div>
  );
};

export default Login;
